






















































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import LayoutDefault from '@/layouts/LayoutDefault.vue'
import ProductService from '@/services/ProductService'
import { namespace } from 'vuex-class'
const Auth = namespace('Auth')
const Product = namespace('Product')
const CartProductStore = namespace('CartProductStore')
const CartProductStoreEdit = namespace('CartProductStoreEdit')
const ModeBuyProduct = namespace('ModeBuyProduct')

@Component({
  components: {
    LayoutDefault
  }
})
export default class ProductList extends Vue {
  @Auth.Getter
  public isLoggedIn!: boolean
  public rows: number = 0
  public hasSearchProductInput: boolean = false
  public imgProductPreview: any = []
  public activeModalAddToCart: boolean = false
  public search_value_current!: string
  public sort: any = 1
  public isLoadingProductSearch: boolean = false

  private currentPage: number = 1
  private perPage: number = 20
  private hasSearchProductResult: boolean = false
  private searchValue: string = ''
  private products: any = []
  private breadcrumbCategory: any = []
  private stockQuantity: any = 0
  private numberOfPage: number = 1
  @ModeBuyProduct.Getter
  private getModeBuyProduct!: number
  @Product.Getter
  private getProductSearch!: string
  @CartProductStore.Getter
  private getCart!: any
  @CartProductStoreEdit.Getter
  private getCartEdit!: any
  @CartProductStore.Action
  private addToCart!: (data: any) => any
  @CartProductStoreEdit.Action
  private addToCartEdit!: (data: any) => any

  created() {
    this.searchValue = this.getProductSearch
    if (this.searchValue) {
      document.title = this.searchValue + 'の商品一覧 | 明治屋ネットストアー'
    }
    this.fetchDataProducts()
  }

  @Watch('sort')
  onProductSearchSortChanged() {
    this.searchValue = this.getProductSearch
    if (this.searchValue) {
      document.title = this.searchValue + 'の商品一覧 | 明治屋ネットストアー'
    }
    this.fetchDataProducts()
  }

  @Watch('$route', { deep: true, immediate: true })
  watchRouteChange() {
    this.currentPage = this.$route.query.page
      ? Number(this.$route.query.page)
      : 1
    this.fetchDataProducts()
  }

  @Watch('getProductSearch')
  onProductSearchChanged() {
    this.searchValue = this.getProductSearch
    if (this.searchValue) {
      document.title = this.searchValue + 'の商品一覧 | 明治屋ネットストアー'
    }
    this.fetchDataProducts()
  }

  private async fetchDataProducts() {
    this.isLoadingProductSearch = true
    await ProductService.getProductBySearch(
      this.getProductSearch,
      this.getModeBuyProduct,
      Number(this.sort)
    ).then(
      (response: any) => {
        this.products = response.data.items
        this.breadcrumbCategory = []
        if (this.products.length !== 0) {
          this.products.forEach((element: any) => {
            if (element.categories.length !== 0) {
              this.breadcrumbCategory.push(element.categories)
            }
          })
          this.breadcrumbCategory = this.breadcrumbCategory.filter(
            (item: any, index: any) =>
              this.breadcrumbCategory
                .map(function(e: any) {
                  return e.name
                })
                .indexOf(item.name) === index
          )
          this.stockQuantity = this.products.stock_quantity
          this.breadcrumbCategory = this.breadcrumbCategory[0]
          this.imgProductPreview = this.products.images
          this.hasSearchProductResult = true
          this.numberOfPage = Math.ceil(this.products.length / this.perPage)
        } else {
          this.hasSearchProductResult = false
        }
        if (this.getProductSearch !== '') {
          this.hasSearchProductInput = true
        } else {
          this.hasSearchProductInput = false
        }
      },
      (error: any) => {
        if (error.response.status === 429) {
          localStorage.clear()
          this.$bvModal.show(`too-many-request`)
        }
        this.products =
          (error.response && error.response.data) ||
          error.message ||
          error.toString()
      }
    )
    this.isLoadingProductSearch = false
  }

  private linkToProductDetail(productItem: any) {
    this.$router.push({
      name: 'product-detail-preview',
      query: { id: productItem.id }
    })
  }

  public async addToCartView(product: any) {
    let tour_mode =
      localStorage.getItem('tour_mode') &&
      localStorage.getItem('tour_mode') === 'on'
        ? true
        : false
    if (tour_mode || this.isLoggedIn === false) {
      this.$bvModal.show('modal-add-to-cart-redirect-tour-mode')
    } else {
      await ProductService.getProductQuantity(product.id).then(
        (response: any) => {
          this.stockQuantity = response.data.items
        },
        (error: any) => {
          if (error.response.status === 429) {
            localStorage.clear()
            this.$bvModal.show(`too-many-request`)
          }
        }
      )
      let productToAdd = {
        id: product.id,
        name: product.product_name,
        product_name_display: product.product_name_display,
        img_url: product.images.length > 0 ? product.images[0].path : null,
        stock_quantity: this.stockQuantity,
        quantity: 1,
        normal_price_excluding_tax: product.normal_price_excluding_tax,
        normal_price_including_tax: product.normal_price_including_tax,
        note: product.note,
        mode: this.getModeBuyProduct,
        skip: 0,
        tax_classification: product.tax_classification,
        temperature_control_name: product.temperature_control.name,
        temperature_control_price: product.temperature_control.price,
        temperature_control_id: product.temperature_control.id
      }
      let itemInCart = []
      if (localStorage.getItem('order_id_edit')) {
        itemInCart = this.getCartEdit.find((e: any) => e.id === product.id)
      } else {
        itemInCart = this.getCart.find((e: any) => e.id === product.id)
      }
      if (itemInCart && itemInCart.quantity > 99) {
        this.$toast(`一度に注文出来る最大個数は「100」`, {
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: false,
          rtl: false
        })
      } else {
        this.activeModalAddToCart = false
        if (this.stockQuantity === 'unlimited') {
          this.checkAddOrder(productToAdd)
        } else {
          if (this.stockQuantity > 0) {
            let cartLength = 0
            let item = []
            if (localStorage.getItem('order_id_edit')) {
              cartLength = this.getCartEdit.length
              item = this.getCartEdit.find((e: any) => e.id === product.id)
            } else {
              cartLength = this.getCart.length
              item = this.getCart.find((e: any) => e.id === product.id)
            }
            if (cartLength > 0) {
              if (!item) {
                this.checkAddOrder(productToAdd)
              }
              if (item && item.quantity < this.stockQuantity) {
                this.checkAddOrder(productToAdd)
              } else if (item.quantity >= this.stockQuantity) {
                if (this.stockQuantity === 0) {
                  this.$toast(`この商品は在庫がありません。`, {
                    timeout: 5000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: false,
                    rtl: false
                  })
                } else {
                  this.$toast(
                    `この商品は` + this.stockQuantity + `個のみが残ります。`,
                    {
                      timeout: 5000,
                      closeOnClick: true,
                      pauseOnFocusLoss: true,
                      pauseOnHover: true,
                      draggable: true,
                      draggablePercent: 0.6,
                      showCloseButtonOnHover: false,
                      hideProgressBar: true,
                      closeButton: 'button',
                      icon: false,
                      rtl: false
                    }
                  )
                }
              }
            } else {
              this.checkAddOrder(productToAdd)
            }
          } else {
            if (this.stockQuantity === 0 || this.stockQuantity === null) {
              this.$toast(`この商品は在庫がありません。`, {
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: false,
                rtl: false
              })
            } else {
              this.$toast(
                `この商品は` + this.stockQuantity + `個のみが残ります。`,
                {
                  timeout: 5000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: false,
                  rtl: false
                }
              )
            }
          }
        }
      }
    }
  }

  get productList() {
    if (this.hasSearchProductResult) {
      return this.products.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      )
    } else {
      return null
    }
  }

  private checkAddOrder(productToAdd: any) {
    if (localStorage.getItem('order_id_edit')) {
      this.$bvModal.show(`modal-notice-add-to-cart-success`)
      this.addToCartEdit(productToAdd)
    } else {
      this.$bvModal.show(`modal-notice-add-to-cart-success`)
      this.addToCart(productToAdd)
    }
  }

  public linkGen(pageNum: number) {
    return pageNum === 1 ? '?' : `?page=${pageNum}`
  }
}
